<template>
  <div id="snp-list">
    <Loader v-if="isLoading" />
    <div v-show="!isLoading">
      <div style="text-align: right !important">
        <vs-button
          type="border"
          icon-pack="feather"
          icon="icon-plus-circle"
          @click="onCreateClick"
          >{{ $l.get("dictionary.create-button") }}</vs-button
        >
      </div>
      <vs-table
        :sst="true"
        @search="handleSearch"
        @sort="handleSort"
        search
        v-if="data"
        :data="data.snpsPage.results"
      >
        <template slot="header">
          <h3>{{ $l.get("snp-list.table-header") }}</h3>
        </template>
        <template slot="thead">
          <vs-th sort-key="rsid">{{ $l.get("snp-list.table-rsid") }}</vs-th>
          <vs-th sort-key="chromosome">
            {{ $l.get("snp-list.table-chromosome") }}
          </vs-th>
          <vs-th sort-key="position">
            {{ $l.get("snp-list.table-position") }}
          </vs-th>
          <vs-th sort-key="about_en_us_text" v-if="$l.isEnUs">
            {{ $l.get("snp-list.table-about") }}
          </vs-th>
          <vs-th sort-key="about_pt_br_text" v-if="$l.isPtBr">
            {{ $l.get("snp-list.table-about") }}
          </vs-th>
          <vs-th sort-key="about_es_es_text" v-if="$l.isEsEs">
            {{ $l.get("snp-list.table-about") }}
          </vs-th>
          <vs-th>{{ $l.get("snp-list.table-actions") }}</vs-th>
        </template>

        <template slot-scope="{ data }">
          <vs-tr :key="indextr" v-for="(tr, indextr) in data">
            <vs-td>{{ tr.rsid }}</vs-td>
            <vs-td>{{ tr.chromosome }}</vs-td>
            <vs-td>{{ tr.position }}</vs-td>
            <vs-td v-if="$l.isPtBr">{{ tr.aboutEnUsText }}</vs-td>
            <vs-td v-if="$l.isEnUs">{{ tr.aboutPtBrText }}</vs-td>
            <vs-td v-if="$l.isEsEs">{{ tr.aboutEsEsText }}</vs-td>
            <!-- <vs-td>{{ tr.criteriaText }}</vs-td> -->
            <vs-td>
              <vs-button
                color="primary"
                type="border"
                icon="edit"
                @click="onSnpEditClick(tr)"
              ></vs-button>
            </vs-td>
          </vs-tr>
        </template>
      </vs-table>
      <div style="margin-top: 20px; text-align: center">
        <vs-pagination
          :total="Math.ceil(data.snpsPage.count / 25)"
          v-model="page"
          v-if="data"
        ></vs-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import ChangeFilterInteractor from "@/business/snp-list/change-filter";
import SelectSnpEditInteractor from "@/business/snp-list/select-snp-edit";
import SelectCreateInteractor from "@/business/snp-list/select-create";
import InitSnpListInteractor from "@/business/snp-list/init-snp-list";
import SnpListScreenController from "@/adapters/controllers/screen-snp-list";
import Loader from "@/application/components/Loader";

export default {
  screen_name: "snp-list",
  components: { Loader },
  data() {
    return {
      controller: null,
      interactors: {
        changeFilter: null,
        selectSnpEdit: null,
        selectCreate: null,
        initSnpList: null
      },
      isLoading: false,
      page: 1,
      search: "",
      sort: "",
      searchTimer: null,
      data: null
    };
  },
  beforeMount() {
    this.controller = this.$injector.get(SnpListScreenController);

    //{ INTERACTORS
    this.interactors.initSnpList = this.$injector.get(InitSnpListInteractor);
    this.interactors.selectCreate = this.$injector.get(SelectCreateInteractor);
    this.interactors.selectSnpEdit = this.$injector.get(
      SelectSnpEditInteractor
    );
    this.interactors.changeFilter = this.$injector.get(ChangeFilterInteractor);
    //} INTERACTORS

    this.controller.setData = this.setData;
    this.controller.getData = this.getData;
    this.controller.setAsLoading = this.setAsLoading;
    this.controller.setAsLoaded = this.setAsLoaded;
  },
  mounted() {
    this.interactors.initSnpList.handle();
  },
  watch: {
    page: function() {
      this.interactors.changeFilter.handle(this.page, this.search, this.sort);
    }
  },
  methods: {
    setData(data) {
      this.data = data;
    },
    getData() {
      return this.data;
    },
    setAsLoading() {
      this.isLoading = true;
    },
    setAsLoaded() {
      this.isLoading = false;
    },
    handleSearch(searching) {
      this.search = searching;
      if (this.searchTimer) {
        clearTimeout(this.searchTimer);
      }
      const _ = this;
      this.searchTimer = setTimeout(() => {
        _.searchTimer = null;
        if (_.page != 1) {
          _.page = 1; // watch force update
        } else {
          _.interactors.changeFilter.handle(_.page, _.search, _.sort);
        }
        //
      }, 500);
    },
    handleSort(key, active) {
      if (key) {
        if (active) {
          const prefix = active == "desc" ? "-" : "";
          this.sort = `${prefix}${key}`;
        } else {
          this.sort = "";
        }
        this.interactors.changeFilter.handle(this.page, this.search, this.sort);
      }
    },
    onSnpEditClick(data) {
      this.interactors.selectSnpEdit.handle(data);
    },
    onCreateClick() {
      this.interactors.selectCreate.handle();
    }
  }
};
</script>

<style lang="scss"></style>
